import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';


const x_logo = '../logo/x.png';
const github_logo = '../logo/github.png';
const linkedin_logo = '../logo/linkedin.png';
// Sample data for team members
const teamMembers = [
    {
        name: 'Ian Dorion',
        role: 'Blockchain Engineer | Founder',
        description: 'Blockchain engineer and full stack developer. I left the aerospace industries to pursue my passion for blockchain technology.',
        image: '../team/me.jpeg',
        avatar: '../team/me_avatar.png',
        linkedIn: 'https://linkedin.com/in/ian-dorion-b4b121238',
        twitter: 'https://twitter.com/DecentralizeIan',
        github: 'https://github.com/Madeindreams',
    },

];

// Wavy animation keyframes
const wavyAnimation = keyframes`
  0%, 100% {
    border-radius: 50%;
  }
  25% {
    border-radius: 60% 40% 54% 46% / 49% 60% 40% 51%;
  }
  50% {
    border-radius: 42% 58% 55% 45% / 55% 48% 52% 45%;
  }
  75% {
    border-radius: 58% 42% 49% 51% / 51% 55% 45% 49%;
  }
`;

const spin = keyframes`
  from {
    transform: rotateY(0);
  }
  to {
    transform: rotateY(360deg);
  }
`;


const TeamSection = styled.section`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
    height: 100vh;
`;

const TeamMemberCard = styled.div`
  width: 250px;
  height: fit-content;
    color: #fff;
  text-align: center;
  padding: 15px;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
    background-image: radial-gradient(
            circle at 10% 0%,
            rgb(56, 7, 40) 16.4%,
            rgb(108, 17, 79) 68.2%,
            rgb(161, 23, 113) 99.1%
    );

    &:hover {
        transform: rotateZ(5deg); // Tilt by 5 degrees on hover
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); // More pronounced shadow
    }
`;

const MemberImageContainer = styled.div`
  position: relative;
  width: 200px;
  height: 200px;
`;

const MemberImage = styled.img`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    object-fit: cover;
    animation: ${wavyAnimation} 3s ease-in-out infinite;
    transition: opacity 0.3s ease-in-out; // Add this line for transition
`;

const MemberName = styled.h3`
  margin-top: 10px;
  font-size: 1.2em;
`;

const MemberTitle = styled.h3`
  margin-top: 10px;
  font-size: 1em;
`;

const MemberDescription = styled.p`
  font-size: 0.9em;
`;

const SocialIcons = styled.div`
  margin-top: 10px;
  display: flex;
  justify-content: center;
  gap: 10px;
`;

const SocialIcon = styled.a`
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
`;

const SocialIconImage = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
    cursor: pointer;

    &:hover {
        animation: ${spin} 0.6s linear;
    }
`;

const Team = () => {
    const [hoveredMember, setHoveredMember] = useState(null);
    return (
        <TeamSection>
            {teamMembers.map((member, index) => (
                <TeamMemberCard
                    key={member.name}
                    onMouseEnter={() => setHoveredMember(index)}
                    onMouseLeave={() => setHoveredMember(null)}
                >
                    <MemberImageContainer>
                        <MemberImage
                            src={member.image}
                            alt={member.name}
                            style={{ opacity: hoveredMember === index ? 0 : 1 }}
                        />
                        <MemberImage
                            src={member.avatar}
                            alt={member.name + " - Hover"}
                            style={{ opacity: hoveredMember === index ? 1 : 0 }}
                        />
                    </MemberImageContainer>
                    <MemberName>{member.name}</MemberName>
                    <MemberTitle>{member.role}</MemberTitle>
                    <MemberDescription>{member.description}</MemberDescription>

                    {/* Social Icons */}
                    <SocialIcons>
                        {member.linkedIn && (
                            <SocialIcon href={member.linkedIn} target="_blank">
                                <SocialIconImage src={linkedin_logo} alt="LinkedIn" />
                            </SocialIcon>
                        )}
                        {member.twitter && (
                            <SocialIcon href={member.twitter} target="_blank">
                                <SocialIconImage src={x_logo} alt="Twitter" />
                            </SocialIcon>
                        )}
                        {member.github && (
                            <SocialIcon href={member.github} target="_blank">
                                <SocialIconImage src={github_logo} alt="GitHub" />
                            </SocialIcon>
                        )}
                    </SocialIcons>
                </TeamMemberCard>
            ))}
        </TeamSection>
    );
};

export default Team;
