import React, { useState, useEffect, useRef } from 'react';
import styled, { StyleSheetManager } from 'styled-components';

const TypingText = styled.span`
  display: inline-block;
  overflow: hidden;
  width: ${(props) => (props.animate === 'true' ? '100%' : '0')};
  height: auto;
  transition: width 3s steps(20, end), height 0s;
  margin:10px;
  span {
    display: inline-block;
   
  }
`;

const TextAnimation = ({ text, animate }) => {
  const [displayedText, setDisplayedText] = useState('');
  const animationStarted = useRef(false);

  useEffect(() => {
    let timer;
    if (animate === 'true' && !animationStarted.current) {
      timer = setTimeout(() => {
        let index = 0;
        const textInterval = setInterval(() => {
          if (index <= text.length) {
            setDisplayedText(text.slice(0, index));
            index++;
          } else {
            clearInterval(textInterval);
            animationStarted.current = true;
          }
        }, 40); // Adjust the interval duration as needed
      }, 1000); // 1000 milliseconds = 1 second delay
    }

    return () => {
      clearTimeout(timer);
    };
  }, [text, animate]);

  return (
    <StyleSheetManager shouldForwardProp={(prop) => prop !== 'animate'}>
      <TypingText animate={animate}>{displayedText}</TypingText>
    </StyleSheetManager>
  );
};

export default TextAnimation;
