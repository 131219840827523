import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const StyledFooter = styled.footer`
position:relative;
  background-color: #f8f9fa;
  padding: 20px 0;
  text-align: center;
`;
const FooterContent = styled.div`
  display: flex;
  justify-content: space-between;
`;

const FooterColumn = styled.div`
  flex: 1;
`;
const Footer = () => {
  const currentYear = new Date().getFullYear();

  return (
    <StyledFooter>
      <Container>
        <div>
        <FooterContent>
          <FooterColumn>
            <p>Info or Links Here 1</p>
          </FooterColumn>
          <FooterColumn>
            <p>Info or Links Here 2</p>
          </FooterColumn>
          <FooterColumn>
            <p>Info or Links Here 2</p>
          </FooterColumn>
        </FooterContent>
          <p>&copy; {currentYear} madeindreams.ca</p>
        </div>
      </Container>
    </StyledFooter>
  );
};

export default Footer;