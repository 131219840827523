import React, { useState } from 'react';
import styled, { keyframes, StyleSheetManager } from 'styled-components';
import TextAnimation from './TextAnimation';
import Step from './Step'
import { Container } from "react-bootstrap"
import backgroundImage from './satellite_bg.png';

const RoadmapSection = styled.section`
position:relative;
  padding: 0 0;
  height:100vh;
  background: rgba(255, 255, 255, 0.1); /* Background color with opacity (adjust alpha as needed) */
`;

const ImageLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url(${backgroundImage}) center/cover no-repeat; /* Background image */
  opacity: 0.5; /* Adjust the opacity as needed */
  z-index:0;
`;

const StyledContainer = styled(Container)`
position:relative;
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  align-items: center;
    z-index: 1;
`;

const typingAnimation = keyframes`
  from {
    width: 250px;
  }
  to {
    width: 100%;
  }
`;


const RoadmapItem = styled.div`
  position: relative;
  text-align: left;
  padding: 20px;
  max-width: 100%;
  width: ${props => (props.ishovered === 'true' ? '100%' : '250px')};
  border-radius: 10px;
background: ${props => props.ishovered === 'true' ? '#fff' : `radial-gradient(
  circle at 10% 0%,
  rgb(56, 7, 40) 16.4%,
  rgb(108, 17, 79) 68.2%,
  rgb(161, 23, 113) 99.1%
)`};
  color: ${props => (props.ishovered === 'true' ? '#000' : '#fff')};
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, width 1s ease, background-color 0.5s ease, color 0.5s ease;
  margin: 15px;
  overflow: hidden;
  border: ${props => (props.ishovered === 'true' ? '2px solid #ba277f' : '2px solid #ba277f')};
  &:hover {
    width: 100%;
    animation: ${typingAnimation}; /* Typewriter animation on hover */
  }
`;

const mvpText = "The MVP introduces a participative world map enabling users to contribute by posting messages at their geographical locations. These contributions not only fund the project but also accrue trust, set to be translated into DAO tokens upon their launch."
const equity = "Acquire Equity Tokens to gain a stake in our ecosystem's revenue. These tokens also confer DAO tokens, granting governance rights and revenue shares. After the vesting period, investors will have the ability to sell back their shares."
const develop = "Get ready to unlock a new era in business outreach and engagement! As we delve into the development phase of our platform, businesses will soon harness the power of our innovative technology to showcase their locations and reach their target audiences across diverse outdoor activity fields. This phase marks a significant leap as the platform integrates geocaching features, enabling businesses to promote their offerings with precision and connect with their desired clientele. By providing a unique location-based service, we're not just enhancing user experience but also opening doors for revenue generation. Stay tuned for an evolution that reshapes the way businesses thrive in the great outdoors!"
const geocaching = "Embark on an exciting global adventure with our upcoming geocaching event! As we unveil our latest web3 features, we're preparing to launch a series of geocaching games that transcend the digital realm and embrace outdoor exploration. Spanning across diverse landscapes, participants from around the world will join in the treasure-hunting experience. Using GPS and clues, they'll seek hidden caches, with the chance to discover prizes and engage in thrilling outdoor activities. Get ready to explore the world in a whole new way and be a part of this global geocaching phenomenon"
const dao = "Get ready for the future as we mark the end of the vesting period for our shareholders, celebrating the launch of the highly anticipated DAO token! This monumental event not only signifies the completion of the vesting period but also embraces a new chapter where every participant is rewarded with the power to steer the course of our platform. With the DAO token in their hands, participants will have a direct say in the future roadmap, functionalities, and initiatives. It's an exciting phase where the community becomes an integral part of the decision-making process, enabling them to shape and redefine the platform's evolution. Welcome to a new era where everyone has a voice in crafting the future of our innovative ecosystem!"


const Roadmap = () => {

    const [mvpIsHovered, setMvpIsHovered] = useState('false');
    const [equityIsHovered, setEquityIsHovered] = useState('false');
    const [developIsHovered, setDevelopIsHovered] = useState('false');
    const [geocachingIsHovered, setGeocachingIsHovered] = useState('false');
    const [daoIsHovered, setDaoIsHovered] = useState('false');


    return (
        <RoadmapSection>
            <StyledContainer>
                <StyleSheetManager shouldForwardProp={(prop) => prop !== 'ishovered'}>
                    <RoadmapItem
                        onMouseEnter={() => setMvpIsHovered('true')}
                        ishovered={mvpIsHovered}
                    >
                        <h3>MVP</h3>
                        <TextAnimation text={mvpText} animate={mvpIsHovered} />
                        <Step isdone={'true'} ishovered={mvpIsHovered} />
                    </RoadmapItem>
                </StyleSheetManager>
                <StyleSheetManager shouldForwardProp={(prop) => prop !== 'ishovered'}>
                    <RoadmapItem
                        onMouseEnter={() => setEquityIsHovered('true')}
                        ishovered={equityIsHovered}
                    >
                        <h3>Equity Tokens</h3>
                        <TextAnimation text={equity} animate={equityIsHovered} />
                        <Step isdone={'true'} ishovered={equityIsHovered}/>
                    </RoadmapItem>
                </StyleSheetManager>
                <StyleSheetManager shouldForwardProp={(prop) => prop !== 'ishovered'}>
                    <RoadmapItem
                        onMouseEnter={() => setDevelopIsHovered('true')}
                        ishovered={developIsHovered}
                    >
                        <h3>Development</h3>
                        <TextAnimation text={develop} animate={developIsHovered} />
                        <Step isdone={'false'} ishovered={developIsHovered}/>
                    </RoadmapItem>
                </StyleSheetManager>
                <StyleSheetManager shouldForwardProp={(prop) => prop !== 'ishovered'}>
                    <RoadmapItem
                        onMouseEnter={() => setGeocachingIsHovered('true')}
                        ishovered={geocachingIsHovered}
                    >
                        <h3>Geocaching</h3>
                        <TextAnimation text={geocaching} animate={geocachingIsHovered} />
                        <Step isdone={'false'} ishovered={geocachingIsHovered} />
                    </RoadmapItem>
                </StyleSheetManager>
                <StyleSheetManager shouldForwardProp={(prop) => prop !== 'ishovered'}>
                    <RoadmapItem
                        onMouseEnter={() => setDaoIsHovered('true')}
                        ishovered={daoIsHovered}
                    >
                        <h3>DAO</h3>
                        <TextAnimation text={dao} animate={daoIsHovered} />
                        <Step isdone={'false'} ishovered={daoIsHovered}  />
                    </RoadmapItem>
                </StyleSheetManager>
            </StyledContainer>
            <ImageLayer />
        </RoadmapSection>
    );
};

export default Roadmap;
