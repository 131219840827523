import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom";
import { Container, Navbar, Nav } from "react-bootstrap"
import logo from '../logo.png';

const StyledNavbar = styled(Navbar)`
  transition: top 0.3s;
  position: fixed;
  margin:0;
  width: 100%;
  z-index: 1000;
`;

const StyledContainer = styled(Container)`
  margin: auto;
  @media (min-width: 768px) {
    width: 100%;
    max-width: 960px; /* Adjust max-width to your preference */
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  margin: 10px;
  color: #6b636e;
  text-transform: uppercase;
&:hover {
  text-decoration: none;
  color:#000000;
}
`;
const LogoImg = styled.img`
  height: 40px;
`;

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height:60px;
`;
const LogoText = styled.div`
 font-size:0.7em;
 margin-left:5px;
 text-transform: uppercase;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const ScrollNavbar = () => {
    const [prevScrollPos, setPrevScrollPos] = useState(window.scrollY);
    const [visible, setVisible] = useState(true);

    const handleScroll = () => {
        const currentScrollPos = window.scrollY;
        setVisible(prevScrollPos > currentScrollPos || currentScrollPos < 10);
        setPrevScrollPos(currentScrollPos);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <StyledNavbar bg="light" variant="light" expand="lg" style={{ top: visible ? '0' : '-100px' }}>
            <StyledContainer>
                <Navbar.Brand>
                        <LogoContainer>
                            <LogoImg src={logo} alt="Logo" />
                            <TextContainer>
                                <LogoText><NavLink to="/">madeindreams</NavLink></LogoText>
                            </TextContainer>
                        </LogoContainer>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" className="dark-toggle" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="me-auto">
                        <NavLink to="/roadmap" >Roadmap</NavLink>
                        <NavLink to="/team" >Team</NavLink>
                        <NavLink to="https://app.madeindreams.ca" target="_blank" rel="noopener noreferrer">APP</NavLink>
                    </Nav>
                </Navbar.Collapse>
            </StyledContainer>
        </StyledNavbar>
    );
};

export default ScrollNavbar;
