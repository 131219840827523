import { BrowserRouter, Route, Routes } from 'react-router-dom'
import styled from "styled-components";
import ScrollNavbar from './components/Navbar';
import 'bootstrap/dist/css/bootstrap.css';
import Footer from './components/Footer';
import Roadmap from './components/Roadmap';
import Team from "./components/Team";

const DappWrapper = styled.div`
  position: relative;
  margin: 0;
  top: 90px;
  left: 0;
  width: 100%;
  height: 100vh;
  right: 0;
  padding: 0;
  background: #FFFFFF;
`;

function App() {
  return (
    <BrowserRouter>
        <ScrollNavbar/>
        <DappWrapper>
            <Routes>
                <Route path="/" element={<Roadmap />} />
                <Route path="/roadmap" element={<Roadmap />} />
                <Route path="/team" element={<Team />} />
            </Routes>
            <Footer/>
        </DappWrapper>
    </BrowserRouter>
  );
}

export default App;
