import React from 'react';
import styled, { keyframes, css, StyleSheetManager } from 'styled-components';
import { FaCheckCircle } from 'react-icons/fa';


const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
`;

const Dot = styled.div`
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: ${props => (props.isdone === 'true' ? 'green' : (props.ishovered === 'true' ? '#ba277f' : '#fff'))};
  display: flex;
  justify-content: center;
  align-items: center;
  ${props =>
    props.isdone === 'true'
      ? css`
          animation: none;
        `
      : css`
          animation: ${bounceAnimation} 1s infinite;
        `}
`;

const CompletedDot = styled(FaCheckCircle)`
  color: white;
`;

const Step = ({ isdone, ishovered }) => {
  return (
    <StyleSheetManager shouldForwardProp={(prop) => prop !== 'animate'}>
    <Dot isdone={isdone} ishovered={ishovered}>
      {isdone === 'true' ? <CompletedDot /> : null}
    </Dot>
    </StyleSheetManager>
  );
};

export default Step;